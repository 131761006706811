import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Bella Pizza",
  pageDescription:
    "Discover how a West Coast pizza joint takes advantage of SpotOn to reduce credit card fees and increase restaurant efficiency.",
  title: "A bigger slice of the pie",
  subTitle: `How Bella Pizza keeps more revenue and staff with SpotOn.`,
  businessName: "Bella Pizza",
  industry: "Restaurants & hospitality, casual dining",
  location: "Portland, Oregon",
  products: [spotonProducts.RESTAURANT, spotonProducts.ORDER],
  description:
    "For 20 years, Bella Pizza has been serving pies to its neighborhood in Portland. And when owner Pete Monk discovered SpotOn, he also found a modern POS partner that supports his staff, guests, and bottom line.",
  quoteData: {
    imgName: "bella-pizza.png",
    quote: `“SpotOn’s commitment to innovations that provide meaningful business results is a big part of the reason Bella Pizza remains committed to SpotOn. Whether it’s employee retention, COVID-19 vaccine monitoring, or menu integration, SpotOn has been a bright spot in our operations during some extremely challenging times.”`,
    personName: "Pete Monk",
    personTitle: "Owner, Bella Pizza",
  },
  goal: `Bella Pizza needed to replace its legacy POS with a cloud-based platform that could simplify staff training, offer online ordering with delivery integration, and enable its owner to manage the restaurant remotely.`,
  solution: `SpotOn Restaurant with SpotOn Order gave Bella Pizza an online menu so customers could order pies from anywhere. And from an app, managers can run payroll, change the menu, or resolve scheduling issues.`,
  // solutionReadMore: {
  //   text: `Read more about their story.`,
  //   url: `https://spoton.com/blog/chiva-loka-spoton-capital-funds/`,
  // },
  results: {
    title: `The results`,
    stats: [
      { title: `Decreased`, description: `training time for new staff` },
      {
        title: `Increased`,
        description: `revenue with cash discounts`,
      },
      {
        title: `Increased`,
        description: `guest satisfaction and positive reviews`,
      },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Selling more slices of pizza",
    imageName: "tonys-pizza.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-02-24",
    href: "/case-studies/tonys-pizza",
    goals: [
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.REACH_NEW_CUSTOMERS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Hot pizza after cold ski days",
    imageName: "base-camp-pizza.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-09",
    href: "/case-studies/base-camp-pizza",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
  {
    title: "Coordinating a Caribbean restaurant",
    imageName: "freetown-road-project.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-28",
    href: "/case-studies/freetown-road-project",
    goals: [
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
];
